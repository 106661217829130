import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import './SearchResult.css';
import SearchResultItem from './SearchResultItem';
import Messages from '../../Localization/Messages';
import { formatMessage } from '../../Localization/formatMessage';
import { capitalizeFirstWord } from '../../Helpers/Converter';

class SearchComponent extends Component {
  state = {
    redirect: false,
  };
  render() {
    const { result, resultFromAD, query } = this.props;
    return (
      <div className="relax-global-search-results relax-results-container relax-is-open search-page">
        {this.renderRedirect()}
        <span className="relax-global-search-title search-result-title">
          {this.searchResultTitle()}
        </span>
        <ul className="relax-search-result-list relax-results search-result-list">
          {result &&
            this.combineResults(result, resultFromAD, query.toUpperCase())
              .slice(0, 5)
              .map((item, index) => (
                <SearchResultItem
                  key={index}
                  isActive={false}
                  item={item}
                  doRedirect={() => this.setRedirect(item)}
                />
              ))}
        </ul>
      </div>
    );
  }

  combineResults = (resultFromDb, resultFromAD, query) => {
    if (!resultFromDb) return;

    if (resultFromAD) {
      const item = resultFromDb.find(item => item.salesId === query);

      if (!item || item.userId !== item.salesId) {
        //If not found item or found but sales id not equal to user id show name for adding
        const newResult = [
          {
            name: this.getFullNameFromADresult(),
            userId: query,
            isDraft: true,
          },
        ].concat(resultFromDb);

        return newResult;
      }
    }

    return resultFromDb;
  };

  searchResultTitle = () => {
    const {
      result,
      query,
      searchInputValue,
      resultFromAD,
      maxLimitCharacter,
      legalUnit,
      businessArea,
    } = this.props;
    if (searchInputValue?.length > maxLimitCharacter)
      return formatMessage(Messages.maxAllowedCharacters, {
        maxLimitCharacter,
      });

    let title = formatMessage(Messages.searchPageResultInfo, {
      query,
      length: result?.length ?? 0,
      legalUnit,
      businessArea: capitalizeFirstWord(businessArea),
    });
    title +=
      !resultFromAD &&
      result?.filter(x => x.salesId.toUpperCase() === query.toUpperCase())
        .length === 0
        ? formatMessage(Messages.noResultFromAD)
        : '';
    return title;
  };

  setRedirect = item => {
    this.setState({
      redirect: true,
      redirectPayload: item,
    });
  };

  getFullNameFromADresult = () => {
    return this.props.resultFromAD
      ? this.props.resultFromAD.firstName +
          ' ' +
          this.props.resultFromAD.lastName
      : null;
  };

  renderRedirect = () => {
    if (this.state.redirect) {
      const item = this.state.redirectPayload;
      return item.salesId ? (
        <Redirect
          push
          to={{
            pathname: `/person/view/${item.salesId}`,
            state: { item: item },
          }}
        />
      ) : (
        <Redirect
          push
          to={{
            pathname: `person/add`,
            state: {
              newSalesId: this.props.isSalesIdShouldBeEditable
                ? ''
                : item.userId,
              newUserId: item.userId,
              newName: item.name,
              enableEditSalesId: this.props.isSalesIdShouldBeEditable,
            },
          }}
        />
      );
    }
  };
}

export default SearchComponent;
